import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';

import { ConfigFile as Config } from '../Config';

const Header = () => {
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        // let token = localStorage.getItem('grumpy-token');
        // if (token && token.length > 0) {
        //     setIsLoggedIn(token);
        // }
    }, []);

    const handleLogin = () => {
        let URL = Config.loginHost;

        window.location.replace('' + URL);
    };

    return (
        <nav className="flex items-center justify-between flex-wrap p-2 fixed z-50 bg-white right-0 left-0">
            <div className="mr-6">
                <span className="blog-title uppercase text-3xl tracking-tight block">
                    <Link to="/">
                        <span className="text-green-500 font-semibold">The</span>
                        <span className="text-green-700 font-bold">Grumpy</span>
                        <span className="text-green-500 font-semibold">Raven</span>
                    </Link>
                </span>
                <span className="blog-title text-gray-800 text-sm sm:text-lg font-light uppercase">
                    Look forward to what’s ahead, be proud of what’s behind
                </span>
            </div>
            <div className="">
                {/* {!isLoggedIn && ( */}
                <div>
                    <button
                        onClick={() => handleLogin()}
                        className="text-green-700 inline-block px-4 py-2 leading-none rounded hover:text-green-700 active:text-white active:bg-green-700 mt-0"
                    >
                        <FontAwesomeIcon icon={faBarsStaggered} className="text-green-700 active:text-white text-lg" />
                    </button>
                </div>
                {/* )} */}
            </div>
        </nav>
    );
};

export default Header;
