const env_vars = process.env;

let configObject = {};

// loop through ENV VARS and pull out ones that start with REACT_APP_
for (const [key, value] of Object.entries(env_vars)) {
    if (key.startsWith('REACT_APP_')) {
        const newKey = key.slice(10);

        configObject[newKey] = value;
    }
}

// add extra properties
const ConfigFile = {
    ...configObject,
};

export { ConfigFile };
