import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { ConfigFile as Config } from '../Config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const Footer = ({ addPhoto }) => {
    const hiddenFileInput = useRef(null);
    const [token, setToken] = useState(null);
    // const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        const tokenString = localStorage.getItem('grumpy-token');
        setToken(tokenString);
    }, []);

    const handleUploadClick = () => {
        hiddenFileInput.current.click();
    };

    const handleSuccess = () => {
        setTimeout(() => addPhoto(), 500);

        return 'Successfully uploaded photo!';
    };

    const handleChange = (event) => {
        const file = event.target.files[0];
        const uploadFunction = handleUploadImage(file);
        toast.promise(
            uploadFunction,
            {
                loading: 'Uploading ...',
                error: (err) => err.message,
                // success: 'uploaded successfully',
                success: () => handleSuccess(),
            },
            { style: { minWidth: 400 } }
        );
    };

    const handleUploadImage = async (file) => {
        const { name } = file;
        try {
            let resp = await axios({
                method: 'post',
                url: Config.apiHost + '/post/' + name,
                data: file,
                headers: {
                    Authorization: token,
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
            });

            let response = resp.data;

            console.log('resp', response);

            return response;
        } catch (err) {
            // console.log('err', err);
            // setErrorMessage(err);
            throw new Error(err);
        }
        // try {
        //     const file = event.target.files[0];
        //     const { name } = file;

        //     let resp = await axios({
        //         method: 'post',
        //         url: Config.apiHost + '/post/' + name,
        //         data: file,
        //         headers: {
        //             Authorization: token,
        //             'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        //         },
        //     });

        //     let response = resp.data;

        //     console.log('resp', response);
        // } catch (e) {
        //     console.log('error uploading');
        // }
    };

    return (
        <>
            <Toaster />
            {token && (
                <div
                    style={{ boxShadow: '0px 75px 100px 20px rgba(0,0,0,0.75)' }}
                    className="flex justify-center items-center fixed bottom-0 left-0 right-0 bg-green-600 opacity-90 p-4 drop-shadow-sm"
                >
                    <button
                        onClick={() => handleUploadClick()}
                        className="bg-white rounded-full p-2 flex justify-center scale-150"
                    >
                        <FontAwesomeIcon icon={faCamera} className="text-green-700" />
                    </button>
                    <input
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        className="hidden"
                        id="file-upload"
                        type="file"
                    />
                </div>
            )}
        </>
    );
};

export default Footer;
