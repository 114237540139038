import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Header from './components/Header';
import Login from './components/Login';
import PhotoList from './components/PhotoList';

function App() {
    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.hash);

        for (const [key, value] of params.entries()) {
            if (key === '#id_token' || key === 'id_token') {
                localStorage.setItem('grumpy-token', value);
                navigate('/');
            }
        }
    }, [location, navigate]);

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<PhotoList />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    );
}

export default App;
