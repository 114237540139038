import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
    //curl -X POST
    //-H "Content-Type: application/x-amz-json-1.1"
    //-H 'X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth'
    //-d '{"AuthParameters": {"USERNAME": "<USER>", "PASSWORD": "<PASS>"}, "AuthFlow": "USER_PASSWORD_AUTH", "ClientId": "239h6civ0uar033ki2uqc6p0cv"}'
    //https://cognito-idp.us-east-1.amazonaws.com/
    const [formData, setFormData] = useState({
        user: {
            username: 'manneh',
            password: '@Darksoulsmoe7#',
        },
    });

    const handleChange = (e) => {
        setFormData({
            user: {
                ...formData.user,
                [e.target.name]: e.target.value,
            },
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log('do login');
        // Auth.signIn(formData.user.username, formData.user.password).then(
        //     (response) => {
        //         console.log('success login??? ', response);
        //     }
        // );
        let resp = await axios.post(
            'https://grumpyraven.auth.us-east-1.amazoncognito.com/',
            {
                AuthParameters: {
                    USERNAME: formData.user.username,
                    PASSWORD: formData.user.password,
                },
                AuthFlow: 'USER_PASSWORD_AUTH',
                ClientId: '78ocr26cbt5nbde5sjbf8ojmf9',
            },
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.InitiateAuth',
                },
            }
        );

        let response = resp.data;

        sessionStorage.setItem(
            'token',
            JSON.stringify(response.AuthenticationResult.AccessToken)
        );

        console.log('login response', response);
    };

    return (
        <figure className="h-screen flex bg-gray-100">
            <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
                <div className="text-primary m-6">
                    <form onSubmit={handleLogin}>
                        <input
                            name="username"
                            type="text"
                            value={formData.user.username}
                            onChange={handleChange}
                            placeholder="Username"
                            className={
                                'w-full p-4 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'
                            }
                        />
                        <input
                            name="password"
                            type="password"
                            value={formData.user.password}
                            onChange={handleChange}
                            placeholder="Password"
                            className={
                                'w-full p-4 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'
                            }
                        />
                        {/* <div className="flex items-center mt-3 justify-center"> */}
                        <div className="flex w-full">
                            <button
                                className={
                                    'w-full bg-indigo-600 hover:bg-indigo-400 py-4 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black'
                                }
                                value="Login"
                                type="submit"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </figure>
    );
};

export default Login;
