import React, { useState, useEffect } from 'react';
// import React, { useState } from 'react';
import moment from 'moment';
// import axios from 'axios';
// import toast from 'react-hot-toast';

// import { ConfigFile as Config } from '../Config';

const Photo = ({ photo, index, removeImage }) => {
    // const [width, setWidth] = useState(0);
    // const [token, setToken] = useState(null);
    const [isImageValid, setIsImageValid] = useState(true);
    const [day, setDay] = useState('');
    // const [successMessage, setSuccessMessage] = useState(undefined);
    // const [errorMessage, setErrorMessage] = useState(undefined);
    // const [fadeOutClasses, setFadeOutClasses] = useState('');

    // useEffect(() => {
    //     const tokenString = localStorage.getItem('grumpy-token');
    //     setToken(tokenString);
    // }, []);

    useEffect(() => {
        let day = moment.unix(photo.date).format('MM/DD/YYYY');
        setDay(day.toString());
    }, [photo]);

    const handleImageLoad = (e) => {
        // setWidth(e.target.width);
    };

    const handleImageError = () => {
        setIsImageValid(false);
    };

    // const handleDeleteImage = async (id) => {
    //     try {
    //         const res = await axios({
    //             method: 'delete',
    //             url: Config.apiHost + '/delete/' + id,
    //             headers: {
    //                 Authorization: token,
    //             },
    //         });

    //         let response = res.data;
    //         // setSuccessMessage(response);
    //         return response;
    //     } catch (err) {
    //         // setErrorMessage(err.message);
    //         throw new Error(err);
    //     }
    // };

    // const fadeOutAndRemove = () => {
    //     setFadeOutClasses('opacity-0');
    // };

    // const handleSuccess = (id) => {
    //     fadeOutAndRemove();
    //     setTimeout(() => removeImage(id), 500);
    //     return 'Deleted: ' + id;
    // };

    // const handleError = () => {
    //     setTimeout(() => {
    //         console.log('errorMessage', errorMessage);
    //         // return errorMessage;
    //         return 'Error Deleting';
    //     }, 500);
    // };

    // const handleDelete = (id) => {
    //     const deleteFunction = handleDeleteImage(id);

    //     toast.promise(
    //         deleteFunction,
    //         {
    //             loading: 'Deleting ...',
    //             error: (err) => err.message,
    //             success: () => handleSuccess(id),
    //         },
    //         { style: { minWidth: 400 } }
    //     );
    // };

    return (
        <>
            {!isImageValid && (
                <div className="bg-gray-300 w-full py-8 flex justify-center items-center rounded-md">
                    <div className="text-lg sm:text-md lg:text-xs text-center">
                        No image available. <br />
                        Wes broke a thing 😞
                    </div>
                </div>
            )}
            {isImageValid && (
                <div className="relative">
                    {day && day.length > 0 && (
                        <div className="rounded-md py-1 px-2 text-xs text-white absolute right-2 top-2 backdrop-blur-sm bg-black/50">
                            {day}
                        </div>
                    )}
                    <img
                        alt={photo.text}
                        src={photo.thumburl}
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                        className="rounded-md items-center shadow-md"
                    />
                </div>
            )}
        </>
    );
};

export default Photo;
